import * as React from "react"

import Container from "../components/container"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Wufoo from "../components/wufoo"

const Reseller = () => (
  <Layout page="internal">
    <Seo title="Become A Reseller" />
    <Container className="container">
      <div className="customWrapper">
        <div className="defaultWrapper">
          <h1>Become A Reseller</h1>
          <Wufoo formHash="zw1v6v81xuploc" formHeight="1017" />
        </div>
      </div>
    </Container>
  </Layout>
)

export default Reseller
