import React, { useEffect } from "react"

const Wufoo = ({
    formHash,
    formHeight
}) => { 

    useEffect(() => {
        const script = document.createElement("script");
        script.src = "https://secure.wufoo.com/scripts/embed/form.js";
        document.body.appendChild(script);

        script.addEventListener('load', () => {
            const form = new window.WufooForm();
            const options = { 'userName':'fermaxaus', 'formHash':formHash, 'autoResize':true, 'height':formHeight, 'async':true, 'host':'wufoo.com', 'header':'hide', 'ssl':true };
            form.initialize(options);
            form.display();
        });

    }, [formHash, formHeight]);

    return (
        <div id={`wufoo-${formHash}`} className="form"> Fill out my <a href={`https://fermaxaus.wufoo.com/forms/${formHash}`}>online form</a>. </div>
    )
}


export default Wufoo